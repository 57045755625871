import { Tooltip as MuiTooltip, SxProps, Theme } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { ReactElement } from "react";

interface TooltipProps {
  hidden?: boolean;
  content: ReactElement;
  children: ReactElement;
  styles?: React.CSSProperties;
  arrowStyles?: SxProps<Theme>;
  tooltipStyles?: SxProps<Theme>;
  tooltipProps?: { [key: string]: any };
  arrowAtRight?: boolean;
  active?: boolean;
}
export const Tooltip = ({
  hidden,
  content,
  children,
  styles,
  arrowStyles,
  tooltipStyles,
  tooltipProps,
  arrowAtRight = false,
  active = true,
}: TooltipProps) => {
  if (hidden) return null;
  if (!active) return children;

  return (
    <MuiTooltip
      arrow
      title={content}
      placement="top"
      data-testid="tooltip"
      PopperProps={{
        sx: {
          zIndex: 10001,
          ['&[data-popper-placement="top"]']: {
            [`& .${tooltipClasses.arrow}`]: {
              transform: "rotate(-180deg) !important",
              left: !arrowAtRight
                ? "calc(51% - 12px) !important"
                : "calc(90% - 12px) !important",
            },
          },
          [`& .${tooltipClasses.arrow}`]: {
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='20' viewBox='0 0 36 20' fill='none'%3E%3Cpath d='M20.1902 4.81689C27.2303 19.9801 30.4845 20 35.3542 20L0.126789 20C6.63384 20 10.4296 12.9507 13.6831 4.81689C16.9367 -0.605684 16.9367 -0.605684 20.1902 4.81689Z' fill='%231B1D37'/%3E%3C/svg%3E\");",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            width: "16px",
            height: "10px",
            "&::before": {
              display: "none",
            },
            ...arrowStyles,
          },
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundImage: "linear-gradient(to bottom, #292c49, #1b1e3d)",
            padding: "12px",
            ...tooltipStyles,
          },
          ...styles,
        },
      }}
      {...tooltipProps}
    >
      {children}
    </MuiTooltip>
  );
};
