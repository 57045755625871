import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/navigation";
import { FC, ReactElement } from "react";
import { Menu, MenuType } from "./Menu";

interface NavMenuItemProps {
  href?: string;
  label?: string;
  icon?: ReactElement;
  isActive?: boolean;
  sx?: BoxProps["sx"];
  menuItems?: MenuType[];
  onClick?: () => void;
}

export interface MyNavItem extends BoxProps {
  isActive?: boolean;
  sx?: BoxProps["sx"];
}

export const NavMenuItem: FC<NavMenuItemProps> = ({
  href = "/",
  label,
  icon,
  isActive = false,
  sx = {},
  menuItems,
  onClick,
}) => {
  const router = useRouter();

  return (
    <>
      <NavItemBox
        isActive={isActive}
        data-testid="nav-menu-item"
        sx={sx as any}
      >
        <Box
          sx={{
            marginRight: "auto",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            "> *": {
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Box
            onClick={() => {
              router.push(href);
              onClick?.();
            }}
            test-id="nav-menu-item"
          >
            {icon && <NavItemIcon minWidth="10%">{icon}</NavItemIcon>}
            <NavItemLabel>{label}</NavItemLabel>
          </Box>
        </Box>
        {menuItems && menuItems?.length > 0 && (
          <Menu
            position="left"
            items={menuItems}
            menuItemStyles={{
              padding: "8px",
              margin: "0 8px",
              borderRadius: "6px",
            }}
          />
        )}
      </NavItemBox>
    </>
  );
};

const NavItemBox = styled(Box)<MyNavItem>`
  height: 40px;
  padding: 0 4px 0 8px;
  object-fit: contain;
  background-color: ${(props) => (props?.isActive ? "#f7f7fb" : "transparent")};
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: ${(props) =>
      props?.isActive ? "#6270c1" : "transparent"};
  }
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f7f7fb;
  }
`;

const NavItemLabel = styled("span")`
  margin: 12px 4px 12px 8px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1b1e3d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 173px;
`;

const NavItemIcon = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
