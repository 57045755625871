import { Box, FormLabel, Input, SelectProps, TextField } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { SelectArrowIcon } from "./Icons";
import {
  Unstable_NumberInput as NumberInput,
  NumberInputProps,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import debounce from "lodash.debounce";

export const Field: React.FC<FieldProps> = ({
  label,
  placeholder,
  defaultValue = "",
  type = "text",
  debounceTime,
  component: { position = "left", styles, render } = {},
  onChange,
  onBlur,
  unDebouncedData,
  max,
  onMaxInputReached,
  dependencies,
  ...props
}) => {
  const [fieldValue, setFieldValue] = useState<string>(defaultValue);

  const onDebouncedOnChange = useRef(
    debounce((value: string, unDebouncedData?: any) => {
      onChange?.(value, unDebouncedData);
    }, debounceTime),
  ).current;

  useEffect(() => {
    if (dependencies?.length && defaultValue && defaultValue !== fieldValue) {
      setFieldValue(defaultValue);
    }
  }, dependencies);

  const handleChange = (value: string) => {
    if (max && value.length > max) {
      // Max length exceeded
      return onMaxInputReached?.();
    }
    setFieldValue(value);
    if (debounceTime) {
      onDebouncedOnChange(value, unDebouncedData);
    } else {
      onChange?.(value);
    }
  };

  return (
    <Box width="100%">
      <FormLabel focused={false}>{label}</FormLabel>
      <Box position="relative" width="100%">
        <Input
          placeholder={placeholder}
          type={type}
          disableUnderline
          fullWidth
          value={fieldValue}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={(e) => onBlur?.(e.target.value)}
          {...props}
        />
        {render && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              ...(position === "right" && { right: "4px" }),
              ...(position === "left" && { left: "4px" }),
              ...styles,
            }}
          >
            {render}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const NumberField: React.FC<NumberFieldProps> = ({
  sx,
  defaultValue,
  placeholder,
  clearable,
  avoidDecimals,
  disableStepper = false,
  onChange,
  icon,
  min = 0,
  max,
}) => {
  // Number field with stepper to increment and decrement
  const [value, setValue] = useState<number | null>(
    defaultValue || defaultValue === 0 ? defaultValue : null,
  );

  useEffect(() => {
    setValue(defaultValue || defaultValue === 0 ? defaultValue : null);
  }, [defaultValue]);

  const handleChange = (value: string | number) => {
    const updatedValue = value === "" ? (clearable ? null : 0) : Number(value);

    if (max && updatedValue !== null && updatedValue > max) {
      setValue(max - 1);
      onChange?.(max - 1);
    } else {
      setValue(updatedValue);
      onChange?.(updatedValue as any);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "e") {
      e.preventDefault();
    }
    if (avoidDecimals && (e.key === "." || e.key === ",")) {
      e.preventDefault();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "40px",
        minWidth: "72px",
        width: "100%",
        ...sx,
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
          ".MuiFormControl-root": { width: "100%" },
        }}
      >
        <TextField
          sx={{
            // remove default up and down arrows
            "input[type=number]::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              MozAppearance: "none",
              appearance: "none",
              margin: "0",
            },
            ".MuiInputBase-root": { overflow: "hidden", height: "40px" },
            "& input": {
              backgroundColor: "#fcfcfd",
              marginLeft: icon ? "46px" : "0",
            },
          }}
          placeholder={placeholder}
          value={value || value === 0 ? value : ""}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyDown}
          type="number"
          InputLabelProps={{ shrink: true }}
          inputMode="numeric"
          InputProps={{
            inputProps: { min: min + 1, pattern: "[0-9]*" },
            startAdornment: icon && (
              <Box
                sx={{
                  userSelect: "none",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  left: "0",
                  padding: "10px",
                  borderRight: "1px solid #ebebf0",
                  borderRadius: "4px",
                  minWidth: "40px",
                }}
              >
                {icon}
              </Box>
            ),
          }}
        />
        <Box
          sx={{
            display: disableStepper ? "none" : "block",
            position: "absolute",
            top: "50%",
            right: "5px",
            transform: "translateY(-50%)",
            // Avoid selecting text when clicking on the buttons
            userSelect: "none",
            border: "1px solid #ebebf0",
            borderRadius: "6px",
            background: "white",
          }}
        >
          <Box
            px="4px"
            py="3px"
            borderBottom="1px solid #ebebf0"
            sx={{
              opacity: max && (value || 0) + 1 >= max ? 0.5 : 1,
              cursor:
                max && (value || 0) + 1 >= max ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              const disabled = max && (value || 0) + 1 >= max;
              if (!disabled) {
                handleChange((value || 0) + 1);
              }
            }}
          >
            <Box
              sx={{
                transform: "rotate(180deg)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            >
              <SelectArrowIcon color="#1B1E3D" w={8} h={8} />
            </Box>
          </Box>
          <Box
            onClick={() => {
              const updatedValue = value || 0;

              const disabled = updatedValue - 1 <= min;
              if (!disabled) {
                return (value || 0) > 0 && handleChange((value || 0) - 1);
              }
            }}
            px="4px"
            py="3px"
            sx={{
              opacity: (value || 0) - 1 <= min ? 0.5 : 1,
              cursor: (value || 0) - 1 <= min ? "not-allowed" : "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <SelectArrowIcon color="#1B1E3D" w={8} h={8} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CustomNumberInput = forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <NumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon />,
          className: "increment",
        },
        decrementButton: {
          children: <RemoveIcon />,
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

export function QuantityInput({
  min = 1,
  max = 99,
  onChange,
}: {
  min?: number;
  max?: number;
  onChange?: (value?: number) => void;
}) {
  return (
    <CustomNumberInput
      onChange={(_, val) => onChange?.(val)}
      aria-label="Quantity Input"
      min={min}
      max={max}
      defaultValue={1}
    />
  );
}

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? "grey.300" : "grey.500"};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`,
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === "dark" ? "grey.300" : "grey.900"};
  background: ${theme.palette.mode === "dark" ? "grey.900" : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? "grey.700" : "grey.200"};
  border-radius: 4px;
  margin: 0 4px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;

  &:hover {
    border-color: ${"blue.400"};
  }

  &:focus {
    border-color: ${"blue.400"};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? "blue.500" : "blue.200"
    };
  }

  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 0;
  border-radius: 999px;
  color: ${theme.palette.mode === "dark" ? "blue.300" : "blue.600"};
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === "dark" ? "blue.800" : "blue.100"};
    cursor: pointer;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`,
);

type ClearableType = {
  clearable: true;
  onChange?: (value: number | null) => void;
};

type NonClearableType = {
  clearable?: false;
  onChange?: (value: number) => void;
};

type NumberFieldProps = {
  sx?: SelectProps["sx"];
  defaultValue?: number | null;
  placeholder?: string;
  icon?: React.ReactNode;
  avoidDecimals?: boolean;
  min?: number;
  max?: number | null;
  disableStepper?: boolean;
} & (ClearableType | NonClearableType);

export interface FieldProps {
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  type?: string;
  value?: string;
  debounceTime?: number;
  unDebouncedData?: any;
  component?: {
    position: "left" | "right";
    styles?: React.CSSProperties;
    render: any;
  };
  onChange?: (value: string, unDebouncedData?: any) => void;
  onBlur?: (value: string) => void;
  max?: number;
  onMaxInputReached?: () => void;
  dependencies?: any[];
  [key: string]: any;
}
